<template>
  <div class="repairs">

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialogTicket" max-width="1100px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>New</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <v-layout row wrap>
                <v-flex xs8>
                  <v-autocomplete
                    v-model="batch_selected"
                    :items="batches"
                    item-text="name"
                    item-value="id"
                    label="batch"
                    clearable
                    prepend-icon="mdi-archive-outline"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
              <v-divider class="ma-7" inset></v-divider>

              <v-layout v-if="batch_selected === null" row wrap>
                <v-flex xs3>
                  <v-text-field
                    v-model="ttn.number"
                    label="number"
                    prepend-icon="mdi-barcode"
                  ></v-text-field>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="ttn.phone"
                    label="phone"
                    prepend-icon="mdi-cellphone-basic"
                  ></v-text-field>
                </v-flex>

                <v-flex xs4>
                  <v-autocomplete
                    label="incoming from the customer"

                    hide-details="auto"
                    disabled

                    prepend-icon="mdi-swap-horizontal"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs1>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="pa-3 ">
                        <v-btn
                          v-if="Object.keys(ttn.data).length === 0"
                          :disabled="!ttn.number || !ttn.phone"
                          @click="searchTTN"
                          color="primary"
                        >
                          <v-icon color="white">mdi-magnify</v-icon>
                        </v-btn>

                        <v-icon v-else large color="primary">mdi-check</v-icon>
                      </div>
                    </template>
                    <span>Get TTN Data</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
              <v-divider v-if="batch_selected === null" class="ma-7" inset></v-divider>

              <v-layout v-if="batch_selected === null" row wrap>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="contractor_selected"
                    :items="contractors"
                    item-text="name_complete"
                    item-value="id"
                    label="contractor"
                    prepend-icon="mdi-text-box-multiple"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout v-if="batch_selected === null" row wrap>
                <v-flex xs4>
                  <v-text-field
                    v-model="customer.pib"
                    label="pib"
                    prepend-icon="mdi-account-details-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="customer.phone"
                    label="phone"
                    prepend-icon="mdi-cellphone-basic"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="customer.email"
                    label="e-mail"
                    prepend-icon="mdi-email-open-outline"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-divider v-if="batch_selected === null" class="ma-7" inset></v-divider>

              <v-layout row wrap
                v-for="(obj, index) in parcel_box"
                :key="`extra-${index}`"
              >

                <v-flex xs1 class="pl-2">{{index+1}}.</v-flex>

                <v-flex xs3 class="pr-2">
                  <v-autocomplete
                    v-model="obj.object"
                    :items="repair_objects"
                    item-text="serial"
                    item-value="id"
                    label="object"
                    hide-details="auto"
                    clearable
                    outlined
                    dense
                    return-object
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs2 class="pr-2">
                  <v-text-field
                    v-if="!obj.object"
                    v-model="obj.imei"
                    label="serial new"
                    hide-details="auto"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-else
                    :value="obj.object.serial"
                    label="serial"
                    hide-details="auto"
                    outlined

                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 class="pr-2">
                  <v-autocomplete
                    v-if="!obj.object"
                    v-model="obj.prod_id"
                    :items="devices"
                    item-text="name"
                    item-value="id"
                    label="device new"
                    hide-details="auto"
                    outlined
                    dense
                  ></v-autocomplete>
                  <v-text-field
                    v-else
                    :value="obj.object.name"
                    label="device"
                    hide-details="auto"
                    outlined

                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs3 class="pr-2">
                  <v-file-input
                    v-model="obj.file"
                    label="act"

                    truncate-length="15"
                    outlined
                    dense

                    multiple
                  ></v-file-input>
<!--                  show-size counter multiple-->
                </v-flex>

                <v-flex xs1 class="align-self-center d-flex justify-center pb-5">
                  <v-icon @click="addDevice(obj, parcel_box)">mdi-plus</v-icon>
                  <v-icon
                      v-show="parcel_box.length > 1"
                      @click="removeDevice(index, parcel_box)"
                    >mdi-delete-outline
                  </v-icon>
                </v-flex>

              </v-layout>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn
              text
              @click="saveTicketsFiles"
              color="green darken-3"
            >save</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogTTN" max-width="900px" persistent>
      <v-container>

        <v-card-title class="primary white--text">
          <span>New TTN</span>
        </v-card-title>
        <v-card>

          <v-card-text>
            <v-container>

              <v-layout row wrap>
                <v-flex xs3>
                  <v-text-field
                    v-model="ttn.number"
                    label="ttn"
                    prepend-icon="mdi-archive-outline"
                  ></v-text-field>
                </v-flex>
                <v-flex xs3>
                  <v-text-field
                    v-model="ttn.phone"
                    label="phone"
                    prepend-icon="mdi-cellphone-basic"
                  ></v-text-field>
                </v-flex>
                <v-flex xs4>
                  <v-autocomplete
                    v-model="ttn.direction"
                    label="direction"

                    :items="directions"
                    item-text="name"
                    return-object

                    hide-details="auto"
                    :disabled="Object.keys(ttn.data).length === 0"

                    prepend-icon="mdi-swap-horizontal"
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs1>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="pa-3 ">
                        <v-btn
                          v-if="Object.keys(ttn.data).length === 0"
                          :disabled="!ttn.number || !ttn.phone"
                          @click="searchTTN"
                          color="primary"
                        >
                          <v-icon color="white">mdi-magnify</v-icon>
                        </v-btn>

                        <v-icon v-else large color="primary">mdi-check</v-icon>
                      </div>
                    </template>
                    <span>Get TTN Data</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn
              :disabled="Object.keys(ttn.data).length === 0 || !ttn.direction"
              @click="saveTTN"
              color="green darken-3"
              text
            >save</v-btn>
          </v-card-actions>

        </v-card>

      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogAddFile" max-width="900px">
      <v-container>

        <v-card-title class="primary white--text">
          <span>New File(s)</span>
        </v-card-title>

        <v-card>
          <v-card-text>
            <v-layout row wrap>
            <v-flex xs7>
              <v-file-input
                v-model="new_ticket_files"

                show-size
                counter

                multiple
              >

              </v-file-input>
            </v-flex>
            <v-flex xs5>
              <v-autocomplete
                v-model="file_type_selected"
                label="file destination"

                :items="file_types_choose"

                item-value="id"
                item-text="name"

                auto-select-first
                clearable

                prepend-icon="mdi-file-cog-outline"
              ></v-autocomplete>
            </v-flex>

          </v-layout>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">cancel</v-btn>
            <v-btn
              @click="saveFiles"
              color="green darken-3"
              text
            >save</v-btn>
          </v-card-actions>
        </v-card>


      </v-container>
    </v-dialog>
<!--    <v-dialog v-model="dialogConfirmStatus" max-width="900px">-->
<!--      <v-container>-->

<!--        <v-card-title class="primary white&#45;&#45;text">-->
<!--          <span>Confirmation</span>-->
<!--        </v-card-title>-->

<!--        <v-card>-->
<!--          <v-card-text>-->
<!--            <v-layout row wrap>-->
<!--              <v-flex xs6>{{ this.expanded[0].place_id }}</v-flex>-->
<!--              <v-flex xs3></v-flex>-->
<!--              <v-flex xs3></v-flex>-->
<!--            </v-layout>-->
<!--            <v-layout row wrap>-->
<!--              <v-flex xs6>{{ this.expanded[0].state_id }}</v-flex>-->
<!--              <v-flex xs3></v-flex>-->
<!--              <v-flex xs3></v-flex>-->
<!--            </v-layout>-->
<!--            <v-layout row wrap>-->
<!--              <v-flex xs6>{{ this.expanded[0].payment_id }}</v-flex>-->
<!--              <v-flex xs3></v-flex>-->
<!--              <v-flex xs3></v-flex>-->
<!--            </v-layout>-->
<!--          </v-card-text>-->
<!--          <v-card-actions>-->
<!--            <v-spacer></v-spacer>-->
<!--            <v-btn text @click="close">cancel</v-btn>-->
<!--            <v-btn-->
<!--              @click="saveTech"-->
<!--              color="green darken-3"-->
<!--              text-->
<!--            >save</v-btn>-->
<!--          </v-card-actions>-->
<!--        </v-card>-->

<!--      </v-container>-->
<!--    </v-dialog>-->

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>
        
<!--Buttons-->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="px-2">
                <v-btn
                  @click="dialogTicket=true"
                  color="primary"
                  :disabled="serial_selected === null"

                >
                  <v-icon color="white">mdi-calendar-plus-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <span>New Repair Ticket</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="px-2">
                <v-btn
                  @click="dialogTTN=true"
                  color="primary"
                  :disabled = "selected.length === 0"
                >
                  <v-icon color="white">mdi-cube-send</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Add TTN</span>
          </v-tooltip>
<!--          <v-tooltip top>-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <div v-bind="attrs" v-on="on" class="px-2">-->
<!--                <v-btn-->
<!--                  color="primary"-->
<!--                  disabled-->
<!--                  depressed-->
<!--                >-->
<!--                  <v-icon color="white">mdi-table-arrow-down</v-icon>-->
<!--                </v-btn>-->
<!--              </div>-->
<!--            </template>-->
<!--            <span>Report download</span>-->
<!--          </v-tooltip>-->

        <v-spacer></v-spacer>

<!--Filters-->
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="filter_batch_selected"
            :items="filter_datas.batches"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="batch"
            prepend-icon="mdi-package-variant"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="filter_place_selected"
            :items="filter_datas.places"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="place"
            prepend-icon="mdi-map-marker-outline"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="filter_state_selected"
            :items="filter_datas.states"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="state"
            prepend-icon="mdi-list-status"
          ></v-autocomplete>
        </v-flex>
        <v-flex xs2 lg2 xl2>
          <v-autocomplete
            v-model="filter_pay_selected"
            :items="filter_datas.pays"
            item-text="name"
            item-value="id"
            hide-selected
            clearable
            label="payment"
            prepend-icon="mdi-circle-multiple-outline"
          ></v-autocomplete>
        </v-flex>

      </v-card-title>
      <v-divider></v-divider>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="tickets"

        show-select

        show-expand
        single-expand
        :expanded.sync="expanded"

        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

        :server-items-length="totalTickets"

        :loading="load"
        loading-text="Repair Tickets loading... Please wait"

        mobile-breakpoint="0"
        class="elevation-1"
      >

        <template slot="progress">
          <v-progress-linear indeterminate color="primary" height="2"></v-progress-linear>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan='headers.length'>
            <v-container>
              <v-card>
                <v-card-title>
                  <v-tabs v-model='tab' right height="35">
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab v-for="t in tabs" :key="t.id">
                      <v-icon left>{{ t.icon }}</v-icon>
                      {{ t.name }}
                    </v-tab>
                  </v-tabs>
                </v-card-title>
                <v-card-text>
                  <v-tabs-items v-model="tab">

                    <v-tab-item key="0">
                      <v-card>
                        <v-card-title>
                          <v-icon color="primary">mdi-car-outline</v-icon>
                          <v-divider class="mx-4" vertical></v-divider>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                            <v-row>

                              <v-col class="d-flex flex-column">
                                <v-card>
                                  <v-card-title>
                                    Device
                                  </v-card-title>
                                  <v-card-text>
                                    buy: {{ item.data_device.purchase_date }}
                                  </v-card-text>
                                  <v-card-text>
                                    sale: {{ item.data_device.sale_date }}
                                  </v-card-text>
                                  <v-card-text>
                                    is ours: {{ item.data_device.is_ours }}
                                  </v-card-text>
                                  <v-card-text>
                                    customer: {{ item.data_device.customer }}
                                  </v-card-text>
                                  <v-card-text
                                      :style="item.data_device.is_seller_warranty === true ? 'color: #1f4d00' : 'color: #4d0000'"
                                  >
                                    warranty seller: {{ item.data_device.warranty_seller_date }}
                                  </v-card-text>
                                  <v-card-text
                                    :style="item.data_device.is_customer_warranty === true ? 'color: #1f4d00' : 'color: #4d0000'"
                                  >
                                    warranty customer: {{ item.data_device.warranty_customer_date }}
                                  </v-card-text>
                                </v-card>
                              </v-col>

                              <v-col class="d-flex flex-column">
                                <v-card>
                                  <v-card-title>
                                    Contacts
                                  </v-card-title>
                                  <v-card-text>
                                    name: {{ item.contact_person.pib }}
                                  </v-card-text>
                                  <v-card-text>
                                    phone: {{ item.contact_person.phone }}
                                  </v-card-text>
                                  <v-card-text>
                                    e-mail: {{ item.contact_person.email }}
                                  </v-card-text>
                                </v-card>
                              </v-col>

                              <v-col class="d-flex flex-column">
                                <v-card>
                                  <v-card-title>
                                    Reason File(s)
                                  </v-card-title>
                                    <v-card-text v-for="(doc, index) in item.supp_files.reason" :key="index">
<!--                                      {{ doc.name }}-->

<!--                                      <v-btn v-if="item.number"-->
<!--                                        icon-->
<!--                                        small-->
<!--                                        class="ma-2"-->
<!--                                        :href="downloadPDF(item)"-->
<!--                                        target="_blank"-->
<!--                                      >-->

<!--                                      <a-->
<!--                                          :href="doc.path"-->
<!--                                          target="_blank"-->
<!--                                      >{{ doc.name }}</a>-->

                                      {{ doc.name }}

                                    </v-card-text>
                                </v-card>
                                <v-divider class="ma10 pa10"></v-divider>
                                <v-card>
                                  <v-card-title>
                                    Warranty File(s)
                                  </v-card-title>
                                    <v-card-text v-for="(doc, index) in item.supp_files.warranty" :key="index">
<!--                                      <a-->
<!--                                          :href="item.warranty_file"-->
<!--                                          target="_blank"-->
<!--                                      >{{ item.warranty_file }}</a>-->
                                      {{ doc.name }}
                                    </v-card-text>
                                </v-card>
                              </v-col>

                            </v-row>
                        </v-card-text>

                      </v-card>
                    </v-tab-item>

                    <v-tab-item key="1">
                      <v-card-title>
                        <v-icon color="primary">mdi-forum-outline</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="px-2">
                              <v-btn
                                @click="refreshTTN()"
                                :disabled = "ttn_selected.length === 0"
                                color="primary"
                              >
                                <v-icon color="white">mdi-refresh</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>Refresh TTN</span>
                        </v-tooltip>

                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card-text>
                        <v-data-table
                            v-model="ttn_selected"

                            :headers="headers_ttn"
                            :items="item.ttns"

                            :items-per-page="-1"
                            :hide-default-footer="true"

                            show-select
                            single-select

                            mobile-breakpoint="0"
                            class="elevation-1"

                      ></v-data-table>
                      </v-card-text>

                    </v-tab-item>

                    <v-tab-item key="2">
                      <v-card-title>
                        <v-icon color="primary">mdi-forum-outline</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>


                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="px-2">
                              <v-btn
                                  @click="downloadAct(false)"
                                  color="primary"
                                  depressed
                                  :disabled="item.supp_comments.supp_diagn === null"
                              >
                                <v-icon>mdi-file-arrow-up-down-outline</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>Act Diagnistic Download</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="px-2">
                              <v-btn
                                  @click="downloadAct(true)"
                                  color="primary"
                                  depressed
                                  :disabled="item.supp_comments.supp_diagn === null ||
                                             item.supp_comments.supp_repair === null ||
                                             item.supp_comments.supp_concl === null"

                              >
                                <v-icon>mdi-file-arrow-up-down</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>Act Repair Download</span>
                        </v-tooltip>


                        <v-spacer></v-spacer>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="px-2">
                              <v-btn
                                @click="dialogAddFile = true"
                                color="primary"
                                :disabled="files_selected.length !== 0"
                              >
                                <v-icon color="white">mdi-plus</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>Add File</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="px-2">
                              <v-btn
                                @click="delFiles"
                                color="primary"
                                :disabled="files_selected.length === 0"
                              >
                                <v-icon color="white">mdi-minus</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>Del File(s)</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="px-2">
                              <v-btn
                                @click="downloadFiles"
                                color="primary"
                                :disabled="files_selected.length === 0"
                              >
                                <v-icon color="white">mdi-folder-zip-outline</v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>File(s) Download</span>
                        </v-tooltip>

                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card-text>
                        <v-container fluid grid-list-md>
                          <v-layout column wrap d-flex>
                            <v-card>

                              <v-card-text row wrap class="d-flex justify-center">
                                <v-flex xs4>
                                  <v-row class="mx-1">
                                    <v-autocomplete
                                    v-model="item.place_id"
                                    :items="places"
                                    item-text="name"
                                    item-value="id"
                                    hide-selected
                                    clearable
                                    label="place"
                                    prepend-icon="mdi-map-marker-outline"
                                    return-object
                                  ></v-autocomplete>
                                  </v-row>
                                  <v-row justify="center">{{ item.place_user }}  {{ item.place_date }}</v-row>
                                </v-flex>
                                <v-flex xs4>
                                  <v-row class="mx-1">
                                    <v-autocomplete
                                    v-model="item.state_id"
                                    :items="maintains"
                                    item-text="name"
                                    item-value="id"
                                    hide-selected
                                    clearable
                                    label="state"
                                    prepend-icon="mdi-list-status"
                                    return-object
                                  ></v-autocomplete>
                                  </v-row>
                                  <v-row justify="center">{{ item.state_user }}  {{ item.state_date }}</v-row>
                                </v-flex>
                                <v-flex xs4>
                                  <v-row class="mx-1">
                                    <v-autocomplete
                                    v-model="item.payment_id"
                                    :items="payments"
                                    item-text="name"
                                    item-value="id"
                                    hide-selected
                                    clearable
                                    label="payment"
                                    prepend-icon="mdi-circle-multiple-outline"
                                    return-object
                                  ></v-autocomplete>
                                  </v-row>
                                  <v-row justify="center">{{ item.payment_user }}  {{ item.payment_date }}</v-row>
                                </v-flex>
                              </v-card-text>
                              <v-divider></v-divider>

                              <v-card-text>
                                <v-row justify="center">
                                  <v-col cols="7">
                                    <v-card>

                                      <v-textarea
                                        v-model="item.supp_comments.supp_diagn"
                                        label="Діагностика (хід і результати діагностики, висновки)"
                                        counter
                                        rows="3"
                                        class="pa-5"
                                      ></v-textarea>
                                    </v-card>
                                    <v-card>
                                      <v-textarea
                                          v-model="item.supp_comments.supp_repair"
                                          label="Ремонт (хід ремонту та використані запчастини)"
                                          counter
                                          rows="3"
                                          class="pa-5"
                                      ></v-textarea>
                                    </v-card>
                                    <v-card>
                                      <v-textarea
                                          v-model="item.supp_comments.supp_concl"
                                          label="Заключення (результати діагностики та ремонту)"
                                          counter
                                          rows="3"
                                          class="pa-5"
                                      ></v-textarea>
                                    </v-card>
                                    <v-card>
                                      <v-textarea
                                        v-model="item.supp_comments.supp_comm"
                                        label="Коментарі (не потрапляють в Акт)"
                                        counter
                                        rows="3"
                                        class="pa-5"
                                      ></v-textarea>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="5">
                                    <v-card>

                                      <v-card-text v-if="item.supp_files.reason.length !== 0">
                                        <v-row style="justify-content: center; font-weight: bold">reason</v-row>
                                        <v-data-table
                                          v-model="files_selected"
                                          :headers="headers_files"
                                          :items="item.supp_files.reason"
                                          show-select
                                          hide-default-footer
                                          :items-per-page="-1"
                                        ></v-data-table>
                                      </v-card-text>
                                      <v-divider v-if="item.supp_files.reason.length !== 0" class="pb-5"></v-divider>

                                      <v-card-text v-if="item.supp_files.warranty.length !== 0">
                                        <v-row style="justify-content: center; font-weight: bold">warranty</v-row>
                                        <v-data-table
                                          v-model="files_selected"
                                          :headers="headers_files"
                                          :items="item.supp_files.warranty"
                                          show-select
                                          hide-default-footer
                                          :items-per-page="-1"
                                        ></v-data-table>
                                      </v-card-text>
                                    </v-card>
                                    <v-divider v-if="item.supp_files.warranty.length !== 0" class="pb-5"></v-divider>

                                    <v-card>
                                      <v-card-text v-if="item.supp_files.diagnostic.length !== 0">
                                        <v-row style="justify-content: center; font-weight: bold">diagnostic</v-row>
                                        <v-data-table
                                          v-model="files_selected"
                                          :headers="headers_files"
                                          :items="item.supp_files.diagnostic"
                                          show-select
                                          hide-default-footer
                                          :items-per-page="-1"
                                        ></v-data-table>
                                      </v-card-text>
                                    </v-card>
                                    <v-divider v-if="item.supp_files.diagnostic.length !== 0" class="pb-5"></v-divider>

                                    <v-card>
                                      <v-card-text v-if="item.supp_files.repair.length !== 0">
                                        <v-row style="justify-content: center; font-weight: bold">repair</v-row>
                                        <v-data-table
                                          v-model="files_selected"
                                          :headers="headers_files"
                                          :items="item.supp_files.repair"
                                          show-select
                                          hide-default-footer
                                          :items-per-page="-1"
                                        ></v-data-table>
                                      </v-card-text>
                                    </v-card>
                                    <v-divider v-if="item.supp_files.repair.length !== 0" class="pb-5"></v-divider>

                                    <v-card>
                                      <v-card-text v-if="item.supp_files.conclusion.length !== 0">
                                        <v-row style="justify-content: center; font-weight: bold">conclusion</v-row>
                                        <v-data-table
                                          v-model="files_selected"
                                          :headers="headers_files"
                                          :items="item.supp_files.conclusion"
                                          show-select
                                          hide-default-footer
                                          :items-per-page="-1"
                                        ></v-data-table>
                                      </v-card-text>
                                    </v-card>
                                    <v-divider v-if="item.supp_files.conclusion.length !== 0" class="pb-5"></v-divider>

                                    <v-card>
                                      <v-card-text v-if="item.supp_files.comments.length !== 0">
                                        <v-row style="justify-content: center; font-weight: bold">comments</v-row>
                                        <v-data-table
                                          v-model="files_selected"
                                          :headers="headers_files"
                                          :items="item.supp_files.comments"
                                          show-select
                                          hide-default-footer
                                          :items-per-page="-1"
                                        ></v-data-table>
                                      </v-card-text>
                                    </v-card>
                                    <v-divider v-if="item.supp_files.comments.length !== 0" class="pb-5"></v-divider>

                                    <v-card>
                                      <v-card-text v-if="item.supp_files.manufacturer.length !== 0">
                                        <v-row style="justify-content: center; font-weight: bold">manufacturer</v-row>
                                        <v-data-table
                                          v-model="files_selected"
                                          :headers="headers_files"
                                          :items="item.supp_files.manufacturer"
                                          show-select
                                          hide-default-footer
                                          :items-per-page="-1"
                                        ></v-data-table>
                                      </v-card-text>
                                    </v-card>

                                  </v-col>
                                </v-row>
                              </v-card-text>
                              <v-divider></v-divider>

                              <v-card-text row wrap class="d-flex justify-center">
                                <v-flex xs3>
                                  <v-text-field
                                    v-model="item.vend_ticket"
                                    label="VEND ticket"
                                    prepend-icon="mdi-ticket-confirmation-outline"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs4>
                                  <v-text-field
                                    v-model="item.new_imei"

                                    label="new IMEI"
                                    prepend-icon="mdi-tag-edit-outline"

                                  ></v-text-field>
<!--                                  :value="item.new_imei"-->
<!--                                  :disabled="item.new_imei"-->
                                </v-flex>
                                <v-flex xs2>
                                  <v-checkbox
                                    v-model="item.is_warranty"
                                    label="warranty">
                                  </v-checkbox>
                                </v-flex>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
<!--                                @click="saveTech(item)"-->
<!--                                @click="dialogConfirmStatus = true"-->
                                <v-btn
                                  @click="saveTech(item)"
                                  text
                                  color="green darken-3"
                                >save</v-btn>
                              </v-card-actions>

                            </v-card>
                          </v-layout>
                        </v-container>
                      </v-card-text>

                    </v-tab-item>

                    <v-tab-item key="4">
                      <v-card-title>
                        <v-icon color="primary">mdi-compare-remove</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-data-table
                        :headers="headersHistory"
                        :items="item.history"
                        :footer-props="{'items-per-page-options': [5, 10, 25, -1]}"
                        :items-per-page="-1"

                        dense
                        mobile-breakpoint="0"
                        class="elevation-1"
                      ></v-data-table>

                      <v-card-text>
                          <v-container fluid grid-list-md>
                            <v-layout row wrap d-flex xs12 sm12 md12>
                              <v-card >
                                <v-card-title>
                                  Тікет виробника
                                </v-card-title>
                                <v-card-text>
                                  розміщення повідомлень від замовника і відповіді виробника, документи
                                </v-card-text>
                              </v-card>
                            </v-layout>

                          </v-container>
                        </v-card-text>

                    </v-tab-item>

                    <v-tab-item key="5">
                      <v-card-title>
                        <v-icon color="primary">mdi-forum-outline</v-icon>
                        <v-divider class="mx-4" vertical></v-divider>
                      </v-card-title>
                      <v-divider></v-divider>

                      <v-card-text>
                          <v-container fluid grid-list-md>
                            <v-layout row wrap d-flex xs12 sm12 md12>
                              <v-card >
                                <v-card-title>
                                  Історія тікета
                                </v-card-title>
                                <v-card-text>
                                  дата, користувач, дія
                                </v-card-text>
                              </v-card>
                            </v-layout>

                          </v-container>
                        </v-card-text>

                    </v-tab-item>

                    <v-tab-item key="6">

                    </v-tab-item>

                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-container>
          </td>
        </template>

      </v-data-table>
    </v-card>

  </div>
</template>

<script>
export default {
  name: 'Repair',

  data() {
    return {
      overlay: false,
      load: false,
      loadObjects: false,

      batches: [],
      batch_selected: null,

      selected: [],
      tickets: [],

      filter_datas: [],

      serial_selected: [],
      device_selected: [],
      contractor_selected: [],

      ttn_selected: [],

      contractors: [],

      types: [],
      type_selected: [],

      producers: [],
      producer_selected: [],

      repair_objects: [],
      devices: [],

      customer: {pib: '', phone: '', email: ''},
      ttn: {number: '', phone: '', data: {}, direction: ''},
      directions: [],

      models: [],
      model_selected: [],

      expanded: [
        {'place_id': 0,
        'state_id': 0,
        'payment_id': 0},
      ],

      maintains: [],
      maintainability: [],
      payments: [],
      places: [],

      files_selected: [],
//      file_types: [],
      file_types_choose: [],

      file_type_selected: [],

      new_ticket_files: [],

      dialogTicket: false,
      dialogDevice: false,
      dialogTTN: false,
      dialogAddFile: false,
//      dialogConfirmStatus: false,

      filter_batch_selected: [],
      filter_place_selected: [],
      filter_state_selected: [],
      filter_pay_selected: [],

      ttn_data: {},

      menu: false,
      picker: new Date().toISOString().substr(0, 10),

      rules: [v => 13 <= v.length || 'Min 13 characters'],

      parcel_box: [{imei: '', prod_id: '', name: ''}],

      tab: null,
      tabs: [
        { id: 1, name: 'Info', icon: 'mdi-car-outline' },

        { id: 2, name: 'TTN Datas', icon: 'mdi-forum-outline'},
        { id: 3, name: 'Tech Support', icon: 'mdi-compare-remove' },

//        { id: 4, name: 'Vend Repair', icon: 'mdi-compare-remove' },
//        { id: 5, name: 'TimeLine', icon: 'mdi-compare-remove' },
//        { id: 6, name: 'Finance', icon: 'mdi-compare-remove' },
      ],

      options: {
        'page': 1,
        'itemsPerPage': 10,
        'sortBy': [],
        'sortDesc': [],
        'groupBy': [],
        'groupDesc': [],
        'mustSort': false,
        'multiSort': false
      },
      sortBy: '-id',
      sortDesc: false,
      totalTickets: 0,

      headers: [

        { text: 'batch', value: 'batch_id' },
        { text: 'id', value: 'id' },

        { text: 'date', value: 'date_create', sortable: false},   // , align: ' d-none d-md-table-cell'

        { text: 'contractor', value: 'contractor', sortable: false},

        { text: 'serial', value: 'serial_device', sortable: false },  // , align: 'center'
        { text: 'device', value: 'name_device', sortable: false },  // , align: 'center'

        { text: 'place', value: 'place_name', sortable: false},
        { text: 'state', value: 'state_name', sortable: false},
        { text: 'payment', value: 'payment_name', sortable: false},

        { text: 'order', value: 'order', sortable: false},

        { text: 'details', value: 'data-table-expand', sortable: false },

      ],
      headers_ttn: [
//          { text: 'id', value: 'id' },
          { text: 'dir', value: 'direction' },

          { text: 'number', value: 'number', sortable: false},
          { text: 'status', value: 'status', sortable: false},

          { text: 'count', value: 'count', sortable: false},
          { text: 'cost', value: 'cost', sortable: false},
          { text: 'payer', value: 'payer', sortable: false},

      ],
      headers_files: [
        { text: 'name', value: 'name' },
//        { text: 'purpose', value: 'purpose' },
      ],

      headersHistory: [],
      headersComments: [],
      headersTicket: [],

    }
  },

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/tickets_repair/',

        params: {
          batch_selected: this.filter_batch_selected,
          place_selected: this.filter_place_selected,
          state_selected: this.filter_state_selected,
          pay_selected: this.filter_pay_selected,

          pagination: this.options,
        },
        data: {},
      })
      .then(function (response) {

        self.batches = response.data.batches;

        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.filter_datas = response.data.filter_datas;

        self.contractors = response.data.contractors;

        self.repair_objects = response.data.repair_objects;
        self.devices = response.data.devices;

        self.directions = response.data.directions;
        self.maintains = response.data.maintains;
        self.payments = response.data.payments;
        self.places = response.data.places;

        self.file_types_choose = response.data.file_types_choose;

        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveTicketsFiles() {
      const self = this;

      self.overlay = true;

      let formData = new FormData();

// files
      for (const [index_box, element] of this.parcel_box.entries()) {
        for (let [index_file, file] of element.file.entries()) {
          formData.append(
              'files', file,
              String(index_box)+'_'+String(index_file)+'__42__' + file.name);
        }
      }

      formData.append('batch', JSON.stringify(this.batch_selected));

      formData.append('ttn', JSON.stringify(this.ttn));
      formData.append('contractor_id', JSON.stringify(this.contractor_selected));
      formData.append('customer', JSON.stringify(this.customer));

      formData.append('parcel_box', JSON.stringify(this.parcel_box));

      formData.append('method', 'create_tickets');
      formData.append('action', 'tickets create');

      self.dialogTicket = false;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {
          batch_selected: this.filter_batch_selected,
          place_selected: this.filter_place_selected,
          state_selected: this.filter_state_selected,
          pay_selected: this.filter_pay_selected,

          pagination: this.options,

        },
        data: formData,

      })
      .then(function (response) {
        self.batches = response.data.batches;

        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.filter_datas = response.data.filter_datas;

        self.ttn = {number: '', phone: '', data: {}, direction: ''};
        self.parcel_box = [{imei: '', prod_id: '', name: ''}];
        self.contractor_selected = [];
        self.batch_selected = null;
        self.customer = {pib: '', phone: '', email: ''};

        self.overlay = false;
        self.load = false;


      })
      .catch(function (error) {
        console.log(error);
      })
    },

    refreshTTN() {
      let self = this;
//      self.load = true;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {
          pagination: this.options,

        },
        data: {

          ttn: this.ttn_selected,
//          tickets: this.selected,

          method: 'refresh_ttn',
          action: 'ttn refresh',

        },
      })
      .then(function (response) {

        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

//        self.dialogTTN = false;

        self.overlay = false;
//        self.load = false;


      })
      .catch(function (error) {
        console.log(error);
      })
    },

    searchTTN() {
      console.log('searchTTN')

      let self = this;
      self.load = true;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {
          pagination: this.options,

        },
        data: {
          number: this.ttn.number,
          phone: this.ttn.phone,

          method: 'get_ttn_data',
          action: 'ttn get data',

        },
      })
      .then(function (response) {
        self.ttn.data = response.data.ttn_data;

        console.log('self.ttn.data', self.ttn.data);

        self.overlay = false;
        self.load = false;

      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveTTN() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {

          pagination: this.options,

        },
        data: {

          ttn: this.ttn,
          tickets: this.selected,

          method: 'create_ttn',
          action: 'ttn create',

        },
      })
      .then(function (response) {

        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.dialogTTN = false;

        self.overlay = false;
        self.load = false;


      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveTech(item) {
      const self = this;
      self.overlay = true;

      let formData = new FormData();

      formData.append('ticket_id', JSON.stringify(item.id))

      formData.append('state', JSON.stringify(item.state_id))
      formData.append('place', JSON.stringify(item.place_id))
      formData.append('payment', JSON.stringify(item.payment_id))

      formData.append('vend_ticket', JSON.stringify(item.vend_ticket))

      if (item.new_imei) {
        formData.append('new_imei', JSON.stringify(item.new_imei))
      }

      formData.append('warr', JSON.stringify(item.is_warranty))
      
//      formData.append('files', item.warranty_file);

      formData.append('diagn', JSON.stringify(item.supp_comments.supp_diagn));
      formData.append('repair', JSON.stringify(item.supp_comments.supp_repair));
      formData.append('concl', JSON.stringify(item.supp_comments.supp_concl));
      formData.append('comm', JSON.stringify(item.supp_comments.supp_comm));

      formData.append('method', 'save_tech');
      formData.append('action', 'tech save');

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {
          pagination: this.options,

        },
        data: formData,
//        data: {
//
//          ttn: this.ttn,
//
//          contractor_id: this.contractor_selected,
//
//          customer: this.customer,
//
//          parcel_box: this.parcel_box,
//
//          method: 'create_tickets',
//          action: 'tickets create',
//
//        },
      })
      .then(function (response) {
        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.overlay = false;

      })
      .catch(function (error) {
        console.log(error);
      })
    },

    saveFiles() {
      const self = this;

      self.dialogAddFile = false;
      self.overlay = true;

      let formData = new FormData();

//      console.log('this.expanded:', this.expanded);

// files
      for (const file of this.new_ticket_files) {
        formData.append('files', file, file.name)
      }


      formData.append('ticket_id', this.expanded[0].id);

      formData.append('file_type', this.file_type_selected);

      formData.append('method', 'add_files');
      formData.append('action', 'files add');

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {
          batch_selected: this.filter_batch_selected,
          place_selected: this.filter_place_selected,
          state_selected: this.filter_state_selected,
          pay_selected: this.filter_pay_selected,

          pagination: this.options,

        },
        data: formData,

      })
      .then(function (response) {
        self.batches = response.data.batches;

        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.filter_datas = response.data.filter_datas;

        self.ttn = {number: '', phone: '', data: {}, direction: ''};
        self.parcel_box = [{imei: '', prod_id: '', name: ''}];
        self.contractor_selected = [];
        self.batch_selected = null;
        self.customer = {pib: '', phone: '', email: ''};

        self.overlay = false;
        self.load = false;

      })
      .catch(function (error) {
        console.log(error);
      })
    },

    delFiles() {
      const self = this;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',

        params: {
          batch_selected: this.filter_batch_selected,
          place_selected: this.filter_place_selected,
          state_selected: this.filter_state_selected,
          pay_selected: this.filter_pay_selected,

          pagination: this.options,

        },
        data: {
          'files_selected': this.files_selected,

          'method': 'del_files',
          'action': 'files del'
        },

      })
      .then(function (response) {
        self.batches = response.data.batches;

        self.tickets = response.data.tickets;
        self.totalTickets = response.data.tickets_count;

        self.filter_datas = response.data.filter_datas;

        self.ttn = {number: '', phone: '', data: {}, direction: ''};
        self.parcel_box = [{imei: '', prod_id: '', name: ''}];
        self.contractor_selected = [];
        self.batch_selected = null;
        self.customer = {pib: '', phone: '', email: ''};

        self.files_selected = [];

//        self.dialogAddFile = false;
        self.overlay = false;
        self.load = false;


      })
      .catch(function (error) {
        console.log(error);
      })

    },

    downloadFiles() {
      const self = this;
      self.overlay = true;

      this.$http({
        method: 'post',
        url: 'ticket_api/tickets_repair/',
        responseType: 'blob',

        params: {
          batch_selected: this.filter_batch_selected,
          place_selected: this.filter_place_selected,
          state_selected: this.filter_state_selected,
          pay_selected: this.filter_pay_selected,

          pagination: this.options,

        },
        data: {
          'files_selected': this.files_selected,

          'method': 'dwnld_files',
          'action': 'files dwnld'
        },

      })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        const partsArray = [
          'files',
//          self.source_selected,
//          sample_name,
//          date_now,
//          time_now,
          '.zip'

        ];
        const fileName = partsArray.join('_')

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        self.overlay = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },

//    saveTechFile(item, part_name) {
//      const self = this;
//      self.overlay = true;
//
//      let formData = new FormData();
//
//      console.log('part_name:', part_name);
//      console.log('item:', item);
//
//      console.log('d:', item.d_file);
//      console.log('r:', item.r_file);
//
//      formData.append('ticket_id', JSON.stringify(item.id));
//
//      formData.append('d_file', item.d_file);
//      formData.append('r_file', item.r_file);
//
//      self.overlay = false;
//    },


    downloadAct(is_full) {
        let self = this;
        self.overlay = true;

        this.$http({
          method: 'post',
          url: 'ticket_api/tickets_repair/',
          responseType: 'blob',

          params: {
            batch_selected: this.filter_batch_selected,
            place_selected: this.filter_place_selected,
            state_selected: this.filter_state_selected,
            pay_selected: this.filter_pay_selected,

            pagination: this.options,

          },
          data: {
            'ticket_id': self.expanded[0].id,
            'is_full': is_full,

            'method': 'dwnld_act',
            'action': 'act dwnld'
          },
        })
        .then(function (response){
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const fileName = self.expanded[0].id + '_act' + '.xlsx';
//          const fileName = 'act.xlsx';

          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          self.selected = [];
          self.overlay = false;
        })
        .catch(function (error) {
          console.log(error);
        })
      },

    // методи додавання/видалення пристроїв
    addDevice(value, prodRow) {
        prodRow.push({
//          id: '*' + Math.floor(Math.random()*1000),
//          id: '',

//          name: '',
          imei: '',

//          phone_1: '',
//          phone_2: '',

//          last: '',

        });
      },

    removeDevice(index, prodRow) {
      prodRow.splice(index, 1);
    },

    result_icon(each) {
//      console.log('each', each);

      switch (each.extention) {

        case 'pdf':
          return 'mdi-file-pdf-box'
        case 'png':
          return 'mdi-file-png-box'
        case 'jpg':
        case 'jpeg':
          return 'mdi-file-jpg-box'
        case 'doc':
        case 'docx':
        case 'docm':
        case 'dotx':
          return 'mdi-file-word-box'
        case 'xlsx':
        case 'xls':
        case 'xltm':
        case 'xlsb':
        case 'xlsm':
        case 'xml':
          return 'mdi-file-excel-box'
        default:
          return 'mdi-equal-box'
      }
    },

    close() {
      this.dialogTicket = false;
      this.dialogDevice = false;
      this.dialogTTN = false;
      this.dialogAddFile = false;
//      this.dialogConfirmStatus = false;

      this.ttn = {number: '', phone: '', data: {}, direction: ''};
      this.parcel_box = [{imei: '', prod_id: '', name: ''}];
      this.contractor_selected = [];
      this.customer = {pib: '', phone: '', email: ''};

    },

  },

  computed: {

//    result_icon(each) {
//      switch (each.extention) {
//        case 'pdf':
//          return 'mdi-file-pdf-box'
//        case '':
//          return ''
//        default:
//          return 'mdi-equal-box'
//      }
//    },

  },

  watch: {
    filter_batch_selected: function() {
      this.getData();
    },
    filter_place_selected: function() {
      this.getData()
    },
    filter_state_selected: function() {
      this.getData()
    },
    filter_pay_selected: function() {
      this.getData()
    },

    files_selected: function() {
      console.log('files_selected:', this.files_selected)
    },

    parcel_box: function() {
      console.log('parcel_box:', this.parcel_box);
    },

    ttn: function() {
      console.log('ttn:', this.ttn);
    },

    batch_selected: function() {
      console.log('batch_selected:', this.batch_selected);
    },

    options: {
      handler () {
        this.getData();
      },
      deep: true,
    },

    expanded: function() {
      console.log('expanded:', this.expanded);
      this.files_selected = [];
    }

  },


}
</script>

<style scoped>

</style>
