<template>
  <div class="repair_orders">

    <v-card>
      <v-card-title>
        <v-icon large color="primary">{{ $route.meta.icon }}</v-icon>
        <v-divider class="mx-4" inset vertical></v-divider>
      </v-card-title>
      <v-divider></v-divider>

      <v-data-table

        :headers="headers"
        :items="orders"

        show-select
        single-select

        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"

        :server-items-length="totalOrders"

        :loading="load"
        loading-text="Orders loading... Please wait"

        mobile-breakpoint="0"
        class="elevation-1">

      </v-data-table>

    </v-card>

  </div>
</template>


<script>
export default {
  name: 'RepairOrders',

  data() {
    return {
      overlay: false,
      load: false,

      orders: [],
      totalOrders: 0,

      options: {
        'page': 1,
        'itemsPerPage': 10,
        'sortBy': [],
        'sortDesc': [],
        'groupBy': [],
        'groupDesc': [],
        'mustSort': false,
        'multiSort': false
      },
      sortBy: '-id',
      sortDesc: false,

      headers: [
        { text: 'id', value: 'id' },
        { text: 'number', value: 'ticket_id' },
        { text: 'contract', value: 'ticket_vendor_id' },
        { text: 'amound', value: 'ticket_vendor_id' },

      ]
    }
  },

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let self = this;
      self.load = true;

      this.$http({
        method: 'get',
        url: 'ticket_api/tickets_orders/',

        params: {
          pagination: this.options,

        },
        data: {},
      })
      .then(function (response) {
        self.orders = response.data.orders;
        self.totalOrders = response.data.orders_count;

        self.load = false;
      })
      .catch(function (error) {
        console.log(error);
      })
    },
  },

  computed: {},

  watch: {
    options: {
      handler () {
        this.getData();
      },
      deep: true,
    },

  },

}
</script>

<style scoped>

</style>