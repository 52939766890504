//import store from '../store'
//import axios from "axios";

import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home'

import Servers from '@/views/dashboard/Servers'
import Payments from '@/views/dashboard/Payments'
import TicketsTime from '@/views/dashboard/TicketsTime'

import Plans from '@/views/settings/Plans'
import Companies from '@/views/settings/Companies'
import Contracts from '@/views/settings/Contracts'

import Staff from '@/views/settings/Staff'
import Contacts from '@/views/settings/Contacts'
import ExpiredContracts from '@/views/settings/ExpiredContracts'

//import Slices from '@/views/trash/Slices'
//import SlicesSource from '@/views/trash/SlicesSource'

import Qty from '@/views/main/Qty'
import Summary from '@/views/main/Summary'
import Balances from '@/views/main/Balances'

import Orders from '@/views/main/Orders'
import Acts from '@/views/main/Acts'

import Tools from '@/views/main/Tools'

import Search from '@/views/main/Search'

import Creation from '@/views/tickets/Creation'
import State from '@/views/tickets/State'

import Sims from '@/views/main/Sims'

import Service from '@/views/tickets/Service'

import Repair from '@/views/tickets/Repair'
import RepairDevices from '@/views/tickets/RepairDevices'
import RepairAccounting from '@/views/tickets/RepairAccounting'
import RepairOrders from '@/views/tickets/RepairOrders'

import QR from '@/views/laboratory/QR'
import Permissions from '@/views/laboratory/Permissions'

import Login from '@/views/Login'
import Logout from '@/views/Logout'
import NotFound from '@/views/NotFound'
import AccessDenied from '@/views/AccessDenied'


//import Workpieces from '@/views/trash/Workpieces'
//import WriteOff from '@/views/trash/WriteOff'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Home',
      icon: 'mdi-home-roof',
      i18: 'menu.main.name',
//      requiresAuth: true,
    },
    component: Home
  },

  {
    path: '/dash_servers',
    name: 'Servers',
    meta: {
      title: 'Servers',
      icon: 'mdi-chemical-weapon',
      i18: 'menu.dashboard.servers',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Servers
  },

  {
    path: '/dash_payments',
    name: 'Payments',
    meta: {
      title: 'Payments',
      icon: 'mdi-piggy-bank-outline',
      i18: 'menu.dashboard.payments',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Payments
  },

  {
    path: '/dash_tickets',
    name: 'ExeTime',
    meta: {
      title: 'TicketsTime',
      icon: 'mdi-timeline-clock-outline',
      i18: 'menu.dashboard.tickets',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: TicketsTime
  },

  {
    path: '/billings',
    name: 'Billings',
    meta: {
      title: 'Billings',
      icon: 'mdi-circle-multiple-outline',
      i18: 'menu.settings.billings',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Plans
  },

  {
    path: '/companies',
    name: 'Companies',
    meta: {
      title: 'Companies',
      icon: 'mdi-store',
      i18: 'menu.settings.companies',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Companies
  },

  {
    path: '/contracts',
    name: 'Contracts',
    meta: {
      title: 'Contracts',
      icon: 'mdi-text-box-outline',
      i18: 'menu.settings.contracts',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Contracts
  },

  {
    path: '/staff',
    name: 'Staff',
    meta: {
      title: 'Staff',
      icon: 'mdi-account-group-outline',
      i18: 'menu.settings.staff',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Staff
  },

  {
    path: '/contracts_ex',
    name: 'Expired Contracts',
    meta: {
      title: 'Expired Contracts',
      icon: 'mdi-text-box-remove-outline',
      i18: 'menu.settings.ex_contracts',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: ExpiredContracts
  },

  {
    path: '/contacts',
    name: 'Contacts',
    meta: {
      title: 'Contacts',
      icon: 'mdi-card-account-mail-outline',
      i18: 'menu.settings.contacts',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Contacts
  },

//  {
//    path: '/slices',
//    name: 'Slices',
//    meta: {
//      title: 'Slices Contract',
//      icon: 'mdi-arrange-bring-to-front',
//      i18: '',
//      requiresAuth: true,
//    },
//    component: Slices,
////    TODO component: () => import
////    component: () => import('@/views/Slices'),
//  },

//  {
//    path: '/slices_source',
//    name: 'SlicesSource',
//    meta: {
//      title: 'Slices Source',
//      icon: 'mdi-arrange-bring-forward',
//      i18: '',
//      requiresAuth: true,
//    },
//    component: SlicesSource,
//  },

  {
    path: '/quantity',
    name: 'Qty',
    meta: {
      title: 'Quantity',
      icon: 'mdi-compare',
      i18: 'menu.main.quantity',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Qty
  },

  {
    path: '/balances',
    name: 'Balances',
    meta: {
      title: 'Balances',
      icon: 'mdi-scale-balance',
      i18: 'menu.main.balance',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Balances
  },

  {
    path: '/orders',
    name: 'Orders',
    meta: {
      title: 'Orders',
      icon: 'mdi-book-multiple',
      i18: 'menu.main.orders',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Orders
  },

  {
    path: '/acts',
    name: 'Acts',
    meta: {
      title: 'Acts',
      icon: 'mdi-book-multiple-outline',
      i18: 'menu.main.acts',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Acts
  },

  {
    path: '/tools',
    name: 'Tools',
    meta: {
      title: 'Tools',
      icon: 'mdi-hammer-wrench',
      i18: 'menu.main.tools',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Tools
  },

  {
    path: '/summary',
    name: 'Summary',
    meta: {
      title: 'Summary',
      icon: 'mdi-file-compare',
      i18: 'menu.main.summary',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Summary
  },

  {
    path: '/search',
    name: 'Search',
    meta: {
      title: 'Search',
      icon: 'mdi-table-search',
      i18: 'menu.main.search',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Search
  },

//  {
//    path: '/workpieces',
//    name: 'Workpieces',
//    meta: {
//      title: 'Workpieces',
//      icon: 'mdi-book-cog',
//      i18: 'menu.flask.workpieces',
//      requiresAuth: true,
//    },
//    component: Workpieces
//  },

//  {
//    path: '/write_off',
//    name: 'WriteOff',
//    meta: {
//      title: 'Write-Offs',
//      icon: 'mdi-book-minus',
//      i18: 'menu.flask.write_off',
//      requiresAuth: true,
//    },
//    component: WriteOff
//  },

  {
    path: '/sims',
    name: 'Sims',
    meta: {
      title: 'Sims',
      icon: 'mdi-sim-outline',
      i18: 'menu.main.sims',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Sims
  },

  {
    path: '/tickets_init',
    name: 'Creation',
    meta: {
      title: 'Creation',
      icon: 'mdi-calendar-start-outline',
      i18: 'menu.tickets.init',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Creation
  },

  {
    path: '/tickets_state',
    name: 'State',
    meta: {
      title: 'State',
      icon: 'mdi-calendar-check-outline',
      i18: 'menu.tickets.state',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: State
  },

  {
    path: '/tickets_service',
    name: 'Service',
    meta: {
      title: 'Service',
      icon: 'mdi-calendar-question-outline',
      i18: 'menu.tickets.service',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Service
  },

  {
    path: '/tickets_repair',
    name: 'Repair',
    meta: {
      title: 'Repair',
      icon: 'mdi-calendar-outline',
      i18: 'menu.tickets.repair',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Repair
  },

  {
    path: '/repair_devices',
    name: 'Devices',
    meta: {
      title: 'Devices',
      icon: 'mdi-calendar-outline',
      i18: 'menu.tickets.devices',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: RepairDevices
  },

  {
    path: '/repair_accounting',
    name: 'Accounting',
    meta: {
      title: 'Accounting',
      icon: 'mdi-calendar-outline',
      i18: 'menu.tickets.accounting',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: RepairAccounting
  },

  {
    path: '/repair_orders',
    name: 'Repair Orders',
    meta: {
      title: 'Repair Orders',
      icon: 'mdi-calendar-outline',
      i18: 'menu.tickets.orders',
      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: RepairOrders
  },

  {
    path: '/qr',
    name: 'QR',
    meta: {
      title: 'QR',
      icon: 'mdi-qrcode-scan',
      i18: 'menu.flask.qr',
//      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: QR
  },

  {
    path: '/permissions',
    name: 'Permissions',
    meta: {
      title: 'Permissions',
      icon: 'mdi-alien-outline',
      i18: 'menu.flask.permissions',
//      requiresAuth: true,
//      requiresPermissions: true,
    },
    component: Permissions
  },

  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Login',
      icon: 'mdi-login',
      i18: 'menu.login',
    },
    component: Login
  },

  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },

  {
    path: '/access_denied',
    name: 'AccessDenied',
    meta: {
      title: 'AccessDenied',
//      icon: 'mdi-login',
      i18: 'Access is Denied',
    },
    component: AccessDenied
  },

//    { path: '/:pathMatch(.*)*', component: PathNotFound },
//    https://stackoverflow.com/questions/40193634/vue-router-redirect-on-page-not-found-404
//    https://router.vuejs.org/guide/migration/#removed-star-or-catch-all-routes
  {
//    path: '*',
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: {
      title: '404 Not Found',
      icon: 'mdi-link-variant-minus',
      i18: '404',
    },
    component: NotFound
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

//router.push({
//  name: 'NotFound',
//  // preserve current path and remove the first char to avoid the target URL starting with `//`
//  params: { pathMatch: this.$route.path.substring(1).split('/') },
//  // preserve existing query and hash if any
//  query: this.$route.query,
//  hash: this.$route.hash,
//});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    localStorage.setItem('redirectPath', to.path);

    if (!localStorage.getItem('auth')) {
      next({
        path: '/login',
        params: {nextUrl: to.fullPath},
      });
    }
    else {
//      console.log('to:', to);
//      console.log('from:', from);
//      console.log('next:', next);
//      console.log('localStorage permissions:', localStorage.getItem("permissions"))


//      TODO check requiresPermissions here?
//      next();

      if (to.matched.some((record) => record.meta.requiresPermissions)) {
          let permissions = JSON.parse(localStorage.permissions);

          if (to.path in permissions) {
            next();
          } else {
            next({
              path: '/access_denied',
              params: {nextUrl: to.fullPath},
            });
          }
      } else {
        next();
      }

    }
  } else {
    next();
  }

});

export default router
